/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { ReactNode, useEffect, useState } from "react";
import Sticky from "react-stickynode";
import Header from "./header/header";
import Footer from "./footer/footer";
import ProgressBar from "components/progress-bar";
import menuItems from "./header/header.data";

const getDocHeight = () => {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );
};

const calculateScrollDistance = () => {
  const scrollTop = window.pageYOffset;
  const windowHeight = window.innerHeight;
  const docHeight = getDocHeight();

  const totalDocScrollLength = docHeight - windowHeight;
  const scrollPostion = Math.floor((scrollTop / totalDocScrollLength) * 100);

  return scrollPostion;
};

// Layout for landing page/hero page.
export default function Layout({ children }: { children: ReactNode }) {
  const [isSticky, setIsSticky] = useState(false);
  const handleStateChange = (status: Sticky.Status) => {
    if (status.status === Sticky.STATUS_FIXED) {
      setIsSticky(true);
    } else if (status.status === Sticky.STATUS_ORIGINAL) {
      setIsSticky(false);
    }
  };

  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(() => {
        // Calculates the scroll distance
        setScrollPos(calculateScrollDistance());
      });
    });
  }, []);

  return (
    <React.Fragment>
      {/* <Sticky innerZ={1001} top={0} onStateChange={handleStateChange}>
        <Header
          includeNavs={true}
          className={`${isSticky ? "sticky" : "unSticky"}`}
          menuItems={menuItems}
        />
        <ProgressBar scroll={scrollPos / 100} />
      </Sticky> */}

      <main>{children}</main>
      {/* <Footer /> */}
    </React.Fragment>
  );
}
