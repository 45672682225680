import React from "react";
import { Container, Heading, ThemeProvider, Text } from "theme-ui";
import { StickyProvider } from "contexts/app/app.provider";
import { SEO_TITLE, SEO_DESC, SEO_AUTHOR, SEO_META } from "../constants";
import { landingTheme } from "../theme";
import Fade from "react-reveal/Fade";

import SEO from "components/seo";
import Layout from "components/layout";
import Hero from "sections/hero";
import SkillLevels from "sections/skill-levels";
import WorkFlow from "sections/how-it-works";
import CoreFeatures from "sections/core-features";
import Coaches from "sections/coaches";
import Package from "sections/package";
import Faq from "sections/faq";
import CommonHead from "components/common-head";
import { useState } from "react";
import SignupModal, { ModalContext } from "components/signup-modal";
import FooterCTA from "sections/footer-cta";
import CommonPostBody from "components/common-post-body";

export default function IndexPage() {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal((val) => !val);

  return (
    <ModalContext.Provider value={{ toggle: toggleModal }}>
      <ThemeProvider theme={landingTheme}>
        <StickyProvider>
          <Layout>
            <CommonHead />
            <SEO
              title={SEO_TITLE}
              description={SEO_DESC}
              author={SEO_AUTHOR}
              meta={SEO_META}
            />
            <div id="root">
              <Container my={10}>
                <Heading as="h1" my={5} variant="heroPrimary" color="black">
                  oneCoach will be shutting down on November 20.
                </Heading>
                <Heading my={5}>It's been a wild ride.</Heading>
                <Heading my={5}>
                  We launched oneCoach with the hope of making self-guided
                  tennis instruction more accessible and readily available on
                  your phone.
                </Heading>

                <Heading my={5}>
                  While some enjoyed the ability to follow along with the
                  oneCoach app anywhere and anytime, many preferred (with no
                  fault of their own) the benefits of in-person instruction.
                </Heading>
                <Heading my={5}>
                  Ultimately we could not build a sustainable business offering
                  our oneCoach tennis programs & videos.
                </Heading>
                <Heading my={5}>
                  We appreciate you taking a chance on us and trying out
                  oneCoach from the beginning!
                </Heading>
                <Heading my={5}>
                  If you're still craving tennis material, there's a ton of free
                  material on YouTube, or hit up your local tennis club and take
                  up some lessons there.
                </Heading>
                <Heading my={5}>Happy tennis!</Heading>
                <Heading my={5}>Richard & the oneCoach team</Heading>
              </Container>
              {/* <CommonPostBody />
              <Hero />
              <Fade>
                <SkillLevels />
              </Fade>
              <Fade>
                <CoreFeatures />
              </Fade>
              <WorkFlow />
              <Fade>
                <Coaches />
              </Fade>
              <Package />
              <Faq />
              <FooterCTA /> */}
            </div>
          </Layout>
          {/* <SignupModal isOpen={showModal} toggle={toggleModal}></SignupModal> */}
        </StickyProvider>
      </ThemeProvider>
    </ModalContext.Provider>
  );
}
